<template>
  <div style="text-align: left">团长列表</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="团长姓名">
      <el-input
        v-model="seachForm.managerName"
        clearable
        size="small"
        placeholder="请输入团长姓名"
      ></el-input>
    </el-form-item>
    <el-form-item label="手机号码" style="margin-left: 20px">
      <el-input
        v-model="seachForm.cellphone"
        clearable
        size="small"
        placeholder="请输入手机号码"
      ></el-input>
    </el-form-item>
    <el-form-item label="社区名称" style="margin-left: 20px">
      <el-input
        v-model="seachForm.communityName"
        clearable
        size="small"
        placeholder="请输入社区名称"
      ></el-input>
    </el-form-item>
    <el-form-item label="状态" style="margin-left: 20px">
      <el-select
        v-model="seachForm.managerState"
        clearable
        size="small"
        placeholder="请选择状态"
      >
        <el-option
          v-for="(item, index) in managerStateOption"
          :key="index"
          :label="item.label"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <!-- <el-button type="primary" size="mini" @click="addgood">新增团长</el-button> -->
  </div>
  <el-table :data="tableData" stripe style="width: 100%; margin-top: 15px">
    <el-table-column type="index"></el-table-column>
    <el-table-column prop="managerName" label="名称" width="180">
    </el-table-column>
    <el-table-column prop="cellphone" label="手机号" width="180">
    </el-table-column>
    <el-table-column prop="villageName" label="社区"> </el-table-column>
    <el-table-column prop="rate1" label="佣金比率" width="120">
    </el-table-column>

    <el-table-column prop="updateDate" label="更新时间" width="200">
    </el-table-column>

    <el-table-column prop="managerState" label="状态" width="180">
      <template #default="scope">
        <el-tag v-if="scope.row.managerState === 'CREATED'">已申请</el-tag>
        <el-tag type="success" v-else-if="scope.row.managerState === 'CHECKED'"
          >审核通过</el-tag
        >
        <el-tag type="info" v-else-if="scope.row.managerState === 'UPDATED'"
          >修改信息</el-tag
        >
        <el-tag type="warning" v-else-if="scope.row.managerState === 'REJECTED'"
          >审核未通过</el-tag
        >
        <el-tag type="danger" v-else-if="scope.row.managerState === 'INVALID'"
          >账户异常</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column prop="address" label="设置状态" width="180">
      <template #default="scope">
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap"
        >
          <el-switch
            v-if="
              scope.row.managerState === 'INVALID' ||
              scope.row.managerState === 'CHECKED'
            "
            style="display: block"
            v-model="scope.row.isvalid"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text=""
            inactive-text=""
            @change="changestatus(scope.row)"
          >
          </el-switch>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 15, 20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
      ],
      seachForm: {
        managerName: '',
        cellphone: '',
        communityName: '',
        managerState: ''
      },
      isvalid: true,
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      managerStateOption: []
    }
  },
  computed:{
    ChairmanSeachForm(){
      return this.$store.state.ChairmanSeachForm
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '团长管理',
        index: '/charimaninfo'
      },
      {
        name: '团长列表',
        index: '/charimaninfo'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getmangerInfo(this.ChairmanSeachForm)
    this.getmanagerState()
  },
  methods: {
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.managerName.trim() === '' ? '' : 'managerName=' + this.seachForm.managerName) + (this.seachForm.cellphone.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' ? '' : '&') + 'cellphone=' + this.seachForm.cellphone) + (this.seachForm.communityName.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' && this.seachForm.cellphone.trim() === '' ? '' : '&') + 'communityName=' + this.seachForm.communityName) + (this.seachForm.managerState.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' && this.seachForm.cellphone.trim() === '' && this.seachForm.communityName.trim() === '' ? '' : '&') + 'managerState=' + this.seachForm.managerState)
      console.log(str)
      const res = await this.$http.get('/nktdec/deparment/departments/1' + str)
      console.log(res.data)
      res.data.content.forEach(item => {
        if (item.managerState === 'CHECKED') {
          item.isvalid = true
        } else if (item.managerState === 'INVALID') {
          item.isvalid = false
        }
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setChairmanSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})

    },
    async clearall() {
      const res = await this.$http.get('/nktdec/deparment/departments/1')
      console.log(res.data)
      this.seachForm.managerName = ''
      this.seachForm.cellphone = ''
      this.seachForm.cellphone = ''
      this.seachForm.communityName = ''
      this.seachForm.managerState = ''
      res.data.content.forEach(item => {
        if (item.managerState === 'CHECKED') {
          item.isvalid = true
        } else if (item.managerState === 'INVALID') {
          item.isvalid = false
        }
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setChairmanSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    },
    async getmanagerState() {
      const res = await this.$http.get('/nktdec/deparment/managerStates')
      console.log(res.data)
      res.data.forEach(item => {
        var key = Object.keys(item)
        var label = Object.values(item)
        this.managerStateOption.push({
          key: key[0],
          label: label[0]
        })
      })
    },
    async handleSizeChange(val) {
      console.log(val)
      const res = await this.$http.get('/nktdec/deparment/departments/1' + '?type=GROUP&pageSize=' + val + (this.seachForm.managerName.trim() === '' ? '' : '&managerName=' + this.seachForm.managerName) + (this.seachForm.cellphone.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' ? '' : '&') + 'cellphone=' + this.seachForm.cellphone) + (this.seachForm.communityName.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' && this.seachForm.cellphone.trim() === '' ? '' : '&') + 'communityName=' + this.seachForm.communityName) + (this.seachForm.managerState.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' && this.seachForm.cellphone.trim() === '' && this.seachForm.communityName.trim() === '' ? '' : '&') + 'managerState=' + this.seachForm.managerState))
      res.data.content.forEach(item => {
        if (item.managerState === 'CHECKED') {
          item.isvalid = true
        } else if (item.managerState === 'INVALID') {
          item.isvalid = false
        }
      })
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setChairmanSeachForm',{...this.ChairmanSeachForm , currentpage : '1' , pageSize: val})
    },
    async handleCurrentChange(val) {
      console.log(val)
      var str = val + '?&pageSize=' + this.pageSize + (this.seachForm.managerName.trim() === '' ? '' : '&managerName=' + this.seachForm.managerName) + (this.seachForm.cellphone.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' ? '' : '&') + 'cellphone=' + this.seachForm.cellphone) + (this.seachForm.communityName.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' && this.seachForm.cellphone.trim() === '' ? '' : '&') + 'communityName=' + this.seachForm.communityName) + (this.seachForm.managerState.trim() === '' ? '' : (this.seachForm.managerName.trim() === '' && this.seachForm.cellphone.trim() === '' && this.seachForm.communityName.trim() === '' ? '' : '&') + 'managerState=' + this.seachForm.managerState)
      const res = await this.$http.get('/nktdec/deparment/departments/' + str)
      res.data.content.forEach(item => {
        if (item.managerState === 'CHECKED') {
          item.isvalid = true
        } else if (item.managerState === 'INVALID') {
          item.isvalid = false
        }
      })
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = val
      this.$store.commit('setChairmanSeachForm',{...this.ChairmanSeachForm , currentpage : val , pageSize: this.pageSize+''})
    },
    async changestatus(row) {
      console.log(row)
      var bol = row.managerState === 'CHECKED' ? false : row.managerState === 'INVALID' ? true : ''
      const res = await this.$http.put('/nktdec/deparment/department/' + row.id, {
        valid: bol
      })
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('保存成功！')
        this.getmangerInfo(this.ChairmanSeachForm)
      }
    },
        query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(obj[i]!==''){
              str+='&'+i+'='+ obj[i]
            }
            }
         }
      }else{
        str+='1'
      }
      return str 
    },
    async getmangerInfo(obj) {
      var str = this.query('',obj)
      const res = await this.$http.get('/nktdec/deparment/departments/' + str)
      console.log(res.data.content)
      if(obj.managerName){
        this.seachForm.managerName = obj.managerName
      }
      if(obj.cellphone){
        this.seachForm.cellphone = obj.cellphone
      }
      if(obj.communityName){
        this.seachForm.communityName = obj.communityName
      }
      if(obj.managerState){
        this.seachForm.managerState = obj.managerState
      }
      res.data.content.forEach(item => {
        if (item.managerState === 'CHECKED') {
          item.isvalid = true
        } else if (item.managerState === 'INVALID') {
          item.isvalid = false
        }
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
    }
  }
}
</script>
<style scoped>
.lineform {
  display: flex;
  justify-content: flex-start;
}
.expendform {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.expendform .el-form-item {
  width: 48%;
}
</style>
